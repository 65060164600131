import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ArtistPostTemplate = ({
  content,
  contentComponent,
  website,
  instagram,
  facebook,
  featuredimageartist,
  tags,
  name,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section-post">

      <section className="page-header">
        <div className="container">
          <div className="content subheaderlinks">
            <Link to="/artists" className="post-header-link">
              <span className="breadcrumbs-links">
                Artists
              </span>
            </Link>
            <span className="spacing-item">/</span>
            <span className="breadcrumbs-links">
              {name}
            </span>
          </div>
        </div>
      </section>

      <div className="container content">
        
      {helmet || ''}

      <section className="section">
        <div className="columns">
          
          <div className="column">
            <h1 className="name is-size-2 has-text-weight-bold is-bold-light">
              {name}
            </h1>
            <div className="artist-body">
            <PostContent content={content} />
            </div>
            {website ? (<p><a href={website} className="sociallinks" target="_blank">Website</a></p>) : null}
            {instagram ? (<p><a href={instagram} className="sociallinks" target="_blank">Instagram</a></p>) : null}
            {facebook ? (<p><a href={facebook} className="sociallinks" target="_blank">Facebook</a></p>) : null}
            {/* {artistcv ? (<p><a href={`/img/${artistcv}`} className="sociallinks" target="_blank">Artists CV</a></p>) : null} */}
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          {featuredimageartist ? (
            <div className="column featured-thumbnail-artist is-6">
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimageartist,
                  alt: `featured image thumbnail for artists ${name}`,
                }}
              />
            </div>
          ) : null}
        </div>
      </section>

      </div>
    </section>
  )
}

ArtistPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ArtistPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ArtistPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        name={post.frontmatter.name}
        shortbio={post.frontmatter.shortbio}
        website={post.frontmatter.website}
        instagram={post.frontmatter.instagram}
        facebook={post.frontmatter.facebook}
        featuredimageartist={post.frontmatter.featuredimageartist}
        helmet={
          <Helmet titleTemplate="%s | Artist">
            <title>{`${post.frontmatter.name}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.shortbio}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.name}
      />
    </Layout>
  )
}

ArtistPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArtistPost

export const pageQuery = graphql`
  query ArtistPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        shortbio
        website
        instagram
        facebook
        tags
        featuredimageartist {
          childImageSharp {
            fluid(maxWidth: 520, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
